import React, {Fragment} from "react";
// Demo styles, see 'Styles' section below for some notes on use.
import "react-accessible-accordion/dist/fancy-example.css";
import interactiveAgency from "../assets/images/preview/interactive-agency.png";
import personalPortfolio from "../assets/images/preview/personal-portfolio.png";
import personalPortfolio2 from "../assets/images/preview/personal-portfolio-landing.png";
import corporateBusiness from "../assets/images/preview/corporate-business.png";
import interiorDesign from "../assets/images/preview/interior-design.png";
import darkPersonal from "../assets/images/preview/dark-personal-portfolio-landing.png";
import creativeLanding from "../assets/images/preview/creative-landing.png";
import creativePortfolio from "../assets/images/preview/creative-portfolio.png";
import designerPortfolio from "../assets/images/preview/designer-portfolio.png";
import homeparticles from "../assets/images/preview/homeparticles.png";
import portfolioHome from "../assets/images/preview/portfolio-home.png";
import service from "../assets/images/preview/service.png";
import serviceDetails from "../assets/images/preview/service-details.png";
import about from "../assets/images/preview/about.png";
import blogList from "../assets/images/preview/blog-list.png";
import blogDetails from "../assets/images/preview/blog-details.png";
import portfolio from "../assets/images/preview/portfolio.png";
import portfolioDetails from "../assets/images/preview/portfolio-details.png";
import contact from "../assets/images/preview/contact.png";
import notFound from "../assets/images/preview/404.png";
import {TabPanel} from "react-tabs";

const portfolioList = [
    {
        url: "personal-portfolio",
        imageUrl: personalPortfolio,
        title: "Personal Portfolio",
        description:
            "Its a personal portfolio tamplate thats can be used your Personal useses,  Freelancer, Designer, and Developer etc.",
        label: "",
    },
    {
        url: "dark-portfolio-landing",
        imageUrl: darkPersonal,
        title: "Personal Portfolio — Dark",
        description:
            "It comes with landing page feature. This tamplate you can be used for your Personal useses,  Freelancer, Designer, and Developer etc.",
        label: "",
    },
    {
        url: "designer-portfolio",
        imageUrl: designerPortfolio,
        title: "Designer Portfolio",
        description:
            "Designer Portfolio is a portfolio website demo for all personal portfolio website. A designer present their works by the designer portfolio home demo..",
        label: "",
    }
];

const Demo = () => {
    return (
        <Fragment>
            {/* Start Banner Area  */}
            <div className="prv-banner-wrapper">
                <div className="container-fluid">
                    <div className="plr--120">
                        <div className="row">
                            <div className="col-lg-4 col-xl-5 align-content-center">
                                <div>
                                    <h2 className="theme-gradient">Autonicals</h2>
                                    <p className="title">
                                        Select theme for your website, <br/>deploy it on your own cloud.
                                    </p>
                                    <div className="purshase-btn">
                                        <a
                                            href="https://calendly.com/autonicals/website"
                                            target="_blank"
                                            className="rn-button-style--2 btn-solid"
                                        >
                                            Human Help. $20/hr
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-8 col-xl-7 align-content-center">
                                <div className="row">
                                    {portfolioList.map((value, index) => (
                                        // Start Single Demo
                                        <div
                                            key={index}
                                            className="col-lg-4 col-md-6 col-sm-6 col-12"
                                        >
                                            <div className="single-demo">
                                                <a
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    href={`/${value.url} `}
                                                >
                                                    <img
                                                        src={value.imageUrl}
                                                        alt="Creative Agency Images"
                                                    />
                                                    <h3 className="title">{value.title}</h3>
                                                    <p className="info">{value.description}</p>
                                                    {value.label ? (
                                                        <div className="label-new">
                                                            <span>{value.label}</span>
                                                        </div>
                                                    ) : (
                                                        ""
                                                    )}
                                                </a>
                                            </div>
                                        </div>
                                        // End Single Demo
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="fullscreen empty-div gradient-overlay"></div>
            {/* End Banner Area  */}
        </Fragment>
    );
};

export default Demo;
